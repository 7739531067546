export function errorMessage(err: any): string {
  const message =
    err &&
    "response" in err &&
    "data" in err.response &&
    err.response.data.detail
      ? err.response.data.detail.message || "Terjadi Kesalahan Pada Aplikasi"
      : "Terjadi Kesalahan Pada Aplikasi";
  return message;
}
