import ApiService from "@/core/services/ApiService";
interface IMedia {
  _id: string;
  nama: string;
  source: string;
  tipe: string;
  cakupan: string;
  province: string;
  provinsi: string;
  fc: Map<string, number>;
  bw: Map<string, number>;
}

const MediaData: Array<IMedia> = [];

export { MediaData, IMedia };

export const getMedia = async (media_like, source, page, limiit) => {
  let response;
  if (source != "" && media_like != "") {
    response = await ApiService.get(
      `/media?source=${source}&media_like=${media_like}&page=${page}&limit=${limiit}`
    );
  } else if (media_like != "") {
    response = await ApiService.get(
      `/media?media_like=${media_like}&page=${page}&limit=${limiit}`
    );
  } else if (source != "") {
    response = await ApiService.get(
      `/media?source=${source}&page=${page}&limit=${limiit}`
    );
  } else {
    response = await ApiService.get(`/media?page=${page}&limit=${limiit}`);
  }

  // const response = await ApiService.post(`/media`, { data: payload });
  return response.data;
};

export const updateMedia = async (id, payload) => {
  try {
    const response = await ApiService.post(`/media/update/${id}`, {
      data: payload,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const deletemedia = async (id) => {
  const response = await ApiService.post(`/media/delete/${id}`, {});
  return response;
};
