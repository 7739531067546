import ApiService from "@/core/services/ApiService";

export const getProjectList = async () => {
    const response = await ApiService.get("/project");
    return response.data;
}

export const isNumber = (evt) => {
    evt = evt ? evt : window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        (charCode !== 46 || charCode !== 190)
    ) {
        evt.preventDefault();
    } else {
        return true;
    }
};


