
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { getMedia, IMedia, MediaData, deletemedia } from "./media";
import ModalEdit from "@/views/master/media/Edit.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default defineComponent({
  name: "LogUserPage",
  components: {
    Datatable,
    ModalEdit,
  },
  setup() {
    const modalEdit = ref();
    const dataSource = ref([
      {
        value: "media_tv",
        label: "Media TV",
      },
      {
        value: "media_cetak",
        label: "Media Cetak",
      },
      {
        value: "news",
        label: "Media Online",
      },
    ]);
    const tableData = ref<Array<IMedia>>(MediaData);
    const tableKey = ref(0);
    const total_data = ref(0);
    const is_loading = ref(false);
    const itemsPerPage = ref(10);
    const currentPage = ref(1);
    const search = ref<string>("");
    const TypeValue = ref("");
    const tableHeader = ref([
      {
        name: "Nama Media",
        key: "nama-media",
        sortable: true,
      },
      {
        name: "Source",
        key: "source",
        sortable: true,
      },
      {
        name: "Tipe",
        key: "tipe",
        sortable: true,
      },
      {
        name: "Cakupan",
        key: "cakupan",
        sortable: true,
      },
      {
        name: "Provinsi",
        key: "provinsi",
        sortable: true,
      },
      {
        name: "Rate BW",
        key: "rate-bw",
        sortable: true,
      },
      {
        name: "Rate FC",
        key: "rate-fc",
        sortable: true,
      },
      {
        name: "Aksi",
        key: "aksi",
      },
    ]);

    const fetchData = async () => {
      is_loading.value = true;
      let media_like = "";
      let source = "";
      // const payload = {
      //   page: currentPage.value,
      //   item: itemsPerPage.value,
      // };

      if (search.value) {
        // payload["search"] = search.value;
        media_like = search.value;
      }

      if (TypeValue.value) {
        source = TypeValue.value;
        // payload["type"] = TypeValue.value;
      }

      const res = await getMedia(
        media_like,
        source,
        currentPage.value,
        itemsPerPage.value
      );
      tableData.value = res.data;
      total_data.value = res.total;
      is_loading.value = false;
      tableKey.value += 1;
    };

    const filterTypeChange = async (value: any) => {
      currentPage.value = 1;
      if (value) {
        TypeValue.value = value;
      } else {
        TypeValue.value = "";
      }
      fetchData();
    };
    const searchItems = () => {
      if (search.value.length > 3) {
        fetchData();
      } else {
        fetchData();
      }
    };

    const currentChange = (current: number) => {
      currentPage.value = current;
      fetchData();
    };

    const itemsPerPageChange = (currentItemsPerPage: number) => {
      itemsPerPage.value = currentItemsPerPage;
      fetchData();
    };

    const showModalEdit = (data) => {
      modalEdit.value?.showModal(data);
    };

    const deleteUser = (id) => {
      Swal.fire({
        title: "Apakah anda yakin ingin mengahapus media tersebut?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
      }).then(async (result) => {
        if (result.value) {
          const loadout = {};
          const res = await deletemedia(id);
          if (res.status == 200) {
            Swal.fire("Deleted!", res.data.message, "success");
            fetchData();
          } else {
            Swal.fire("Gagal!", "", "warning");
          }
        }
      });
    };

    onMounted(() => {
      setCurrentPageTitle("Daftar Media");
      fetchData();
      MenuComponent.reinitialization();
    });

    return {
      tableData,
      tableKey,
      total_data,
      is_loading,
      itemsPerPage,
      currentPage,
      search,
      tableHeader,
      fetchData,
      TypeValue,
      filterTypeChange,
      dataSource,
      searchItems,
      modalEdit,
      showModalEdit,
      deleteUser,
      currentChange,
      itemsPerPageChange,
    };
  },
});
