
import { defineComponent, ref, getCurrentInstance } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { isNumber } from "@/core/helpers/generalHelper";
import { updateMedia } from "./media";
import { hideModal } from "@/core/helpers/dom";
import { errorMessage } from "@/global-helper";
export default defineComponent({
  name: "edit-user",
  emit: ["fetch-data"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup(props, { emit }) {
    const submitButton1 = ref<HTMLButtonElement | null>(null);
    const modalEditMedia = ref<HTMLElement | null>(null);
    const idMedia = ref("");
    const FormMedia = ref({
      nama: "",
      source: "",
      stasiun: "",
      tipe: "",
      cakupan: "",
      provinsi: "",
      bw: {
        lebar: 0,
        rate: 0,
      },
      fc: {
        lebar: 0,
        rate: 0,
      },
    });

    const FormMediaValidator = Yup.object().shape({
      source: Yup.string().required("Source harus diisi"),
      tipe: Yup.string().required("Tipe harus diisi"),
      cakupan: Yup.string().required("Cakupan harus diisi"),
      media: Yup.string().when("source", {
        is: (source) => source === "news" || source === "media_cetak",
        then: Yup.string()
          .required("Media harus diisi")
          .when("source", {
            is: "news",
            then: Yup.string()
              .required()
              .matches(
                /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+(?:[a-zA-Z]{2,})$/,
                "Nama media tidak sesuai"
              ),
          }),
        otherwise: Yup.string().notRequired(),
      }),
      program: Yup.string().when("source", {
        is: (val) => val === "media_tv",
        then: Yup.string().required("Program harus diisi"),
        otherwise: Yup.string().notRequired(),
      }),
      stasiun: Yup.string().when("source", {
        is: (val) => val === "media_tv",
        then: Yup.string().required("Stasiun TV harus diisi"),
        otherwise: Yup.string().notRequired(),
      }),
      lebar_bw: Yup.string().when("tipe", {
        is: (val) => val === "media_cetak",
        then: Yup.string().required("Lebar harus diisi"),
        otherwise: Yup.string().notRequired(),
      }),
      rate_bw: Yup.string().when("tipe", {
        is: (val) => val === "media_cetak",
        then: Yup.string().required("Rate harus diisi"),
        otherwise: Yup.string().notRequired(),
      }),
      lebar_fc: Yup.string().when("tipe", {
        is: (val) => val === "media_cetak",
        then: Yup.string().required("Lebar harus diisi"),
        otherwise: Yup.string().notRequired(),
      }),
      rate_fc: Yup.string().when("tipe", {
        is: (val) => val === "media_cetak",
        then: Yup.string().required("Rate harus diisi"),
        otherwise: Yup.string().notRequired(),
      }),
      provinsi: Yup.string().when("cakupan", {
        is: (val) => val === "nasional",
        then: Yup.string().required("Provinsi harus diisi"),
        otherwise: Yup.string().notRequired(),
      }),
    });
    const mediaNameFormatter = () => {
      if (FormMedia.value.source && FormMedia.value.source === "news") {
        FormMedia.value.nama = FormMedia.value.nama
          .toLocaleLowerCase()
          .replaceAll(/\s/g, "");
      }
    };
    const changeCakupan = () => {
      if (FormMedia.value.cakupan == "internasional") {
        FormMedia.value.provinsi = "-";
      }
    };

    const editMedia = async () => {
      if (submitButton1.value) {
        submitButton1.value.setAttribute("data-kt-indicator", "on");
        let loadout = FormMedia.value;
        const result: any = await updateMedia(idMedia.value, loadout);
        if (result.status == 200 || result.status == 204) {
          Swal.fire({
            text: "Berhasil Menyimpan!",
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          }).then(() => {
            emit("fetch-data");
            hideModal(modalEditMedia.value);
          });
        } else {
          const message = errorMessage(result);
          Swal.fire({
            text: `Gagal Menyimpan! ${message}`,
            icon: "error",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          });
        }
        submitButton1.value?.removeAttribute("data-kt-indicator");
      }
    };

    const showModal = (data) => {
      idMedia.value = data._id;
      FormMedia.value.nama = data.nama;
      if (data.stasiun) {
        FormMedia.value.stasiun = data.stasiun;
      } else {
        FormMedia.value.stasiun = "";
      }
      FormMedia.value.source = data.source;
      FormMedia.value.tipe = data.tipe;
      FormMedia.value.cakupan = data.cakupan;
      FormMedia.value.provinsi = data.provinsi ? data.provinsi : "";
      if ("bw" in data && data.bw !== null) {
        FormMedia.value.bw.lebar = data.bw.lebar ? data.bw.lebar : 0;
        FormMedia.value.bw.rate = data.bw.rate ? data.bw.rate : 0;
      } else {
        FormMedia.value.bw.lebar = 0;
        FormMedia.value.bw.rate = 0;
      }
      if ("fc" in data && data.fc !== null) {
        FormMedia.value.fc.lebar = data.fc.lebar ? data.fc.lebar : 0;
        FormMedia.value.fc.rate = data.fc.rate ? data.fc.rate : 0;
      } else {
        FormMedia.value.fc.lebar = 0;
        FormMedia.value.fc.rate = 0;
      }
    };

    return {
      isNumber,
      FormMedia,
      FormMediaValidator,
      showModal,
      changeCakupan,
      modalEditMedia,
      submitButton1,
      idMedia,
      editMedia,
      mediaNameFormatter,
    };
  },
});
